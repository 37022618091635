<template>
  <div class="pageview">
    <!-- <van-nav-bar title="便民服务" fixed /> -->
    <!-- banner切换 -->
    <van-swipe class="homebanner"
      :autoplay="3000"
      indicator-color="white">
      <van-swipe-item v-for="banner in bannerImgs"
        :key="banner"><img :src="banner" /></van-swipe-item>
    </van-swipe>
    <h3 class="grouptitle">吉利华阳企业平台</h3>
    <van-grid class="wktype"
      :border="false">
      <van-grid-item icon="photo-o"
        @click="gotoUrl('/huayang')">
        <van-image class="iconimg"
          :src="require('../../assets/wangshangbanshi.png')"></van-image>
        <span class="wktext">中介超市</span>
      </van-grid-item>
      <van-grid-item icon="photo-o"
        @click="gotoUrl('/huayang/serve')">
        <van-image class="iconimg"
          :src="require('../../assets/shequhuodong.png')"></van-image>
        <span class="wktext">资源看板</span>
      </van-grid-item>
      <van-grid-item icon="photo-o"
        @click="gotoUrl('/huayang/classroom')">
        <van-image class="iconimg"
          :src="require('../../assets/zixunzhongxin.png')"></van-image>
        <span class="wktext">知识讲堂</span>
      </van-grid-item>
      <template>
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/huayang/interaction')">
          <van-image class="iconimg"
            :src="require('../../assets/shequfengcai.png')"></van-image>
          <span class="wktext">区企互动</span>
        </van-grid-item>
      </template>
    </van-grid>
    <br class="brakline" />
    <h3 class="grouptitle">明星企业</h3>
    <van-swipe class="my-swipe"
      :autoplay="5000"
      indicator-color="white">
      <van-swipe-item v-for="item in AgencyList"
        :key="item">
        <img src=""
          alt=""
          style="width:100%">
        <router-link :to="'/huayang/huayang/company/' + item.AId">
          <div class="swipeBox">
            <div>
              {{ item.Name
              }}
              <van-icon style="padding-left: 10px"
                size="14"
                color="red"
                name="fire" />
            </div>
            <div class="blueClass">
              <span style="padding-right: 5px"
                v-for="items in item.ClassifyName"
                :key="items">{{ items }}</span>
            </div>
            <div class="van-multi-ellipsis--l3">{{ item.Summary }}</div>
          </div>
        </router-link>
      </van-swipe-item>
    </van-swipe>
    <br class="brakline" />
    <h3 class="grouptitle">园区动态</h3>
    <div class="information">
      <div class="center"
        v-for="item in NoticeList"
        :key="item.index">
        <div @click="gotoUrl('/huayang/classroom/particulars/' + item.GNId)">
          <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
          <div class="Content">
            <div style="display: inline-block"
              class="ContentA">
              <img v-if="item.ThemeImg"
                :src="item.ThemeImg"
                alt="" />
            </div>
            <div style="display: inline-block"
              class="ContentB">
              <span class="van-multi-ellipsis--l3">{{ item.Summary }}</span>
              <br />
              <span class="centertime">{{ item.IssueTime }}</span>
            </div>
          </div>
        </div>
      </div>
      <van-empty description="暂无资讯"
        v-if="!NoticeList.length" />
    </div>
    <br class="brakline" />
    <h3 class="grouptitle">平台简介</h3>
    <div style="padding: 5px 20px">
      <div>
        平台名称: <span>{{ appInfo.Name }}</span>
      </div>
      <div style="margin: 10px 0">
        联系电话: <span class="blueClass">{{ appInfo.TouchTel }}</span>
      </div>
      <div style="font-size: 14px"
        v-html="appInfo.WorkTime"></div>
    </div>
    <!-- 登录按钮 -->
    <div class="flexOn"
      @click="onClickRight()">企业登录</div>
  </div>
</template>

<script>
import config from "@/config/index";
// import { mapGetters } from 'vuex'
import { WeGetAgencyPage, WeGetGoodNewsPage } from "@/api/Hyapi";
import { WeGetRealInfoPage } from "@/api/RealInfo";
import { WeGetAppletInfo } from "@/api/index";
// import TipsMiniProject from "@/components/TipsMiniProject";
// import Tabbar from "@/components/Tabbar";
import wx from "weixin-js-sdk";
import { setOpenId, getOpenId, getaccToken, setaccToken } from "@/utils/auth";

export default {
  // computed: {
  //   ...mapGetters([
  //     'openId',
  //   ])
  // },
  components: {
    // Tabbar,
    // TipsMiniProject,
  },
  data () {
    return {
      appInfo: {},
      bannerImgs: [],
      active: 0,
      NoticeList: [],
      area: "",
      AgencyFrom: {
        isTop: 1,
      },
      listfrom: {
        page: 1,
        isTop: 1,
      }, //查询传参
      AgencyList: [],
      arrimg: [],
    };
  },
  created () {
    this.getAppInfo();
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      // this.$store.dispatch('user/setopenid', { openid: this.$route.query['openid'] })
      setOpenId(this.$route.query["openid"]);
    }
    this.area = config.area;
    this.getNoticeList();
    this.GetAgencyPage();
  },
  methods: {
    // 跳转登录
    onClickRight () {
      if (getaccToken() != undefined) {
        this.$router.push({ name: 'firm' });
      } else {
        this.$router.push("/huayang/firmlogin");
      }
    },
    //跳转到公共页面
    gotoUrl (path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    //小程序跳转到小程序
    gotominiProgram (name) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/openminip?name=" + name,
        });
      } else {
        alert("请在小程序中打开");
      }
    },
    // 通知消息
    getNoticeList () {
      WeGetGoodNewsPage(this.listfrom).then((res) => {
        this.NoticeList = res.data.data;
        for (let i = 0; i < this.NoticeList.length; i++) {
          this.NoticeList[i].IssueTime = this.dataFormat(this.NoticeList[i].IssueTime);
        }
      });
    },
    // 小程序展示数据
    getAppInfo () {
      WeGetAppletInfo().then((res) => {
        var list = res.data;
        if (list.code == 0) {
          var imgs = [];
          imgs[0] = list.data.SysPic1Url;
          imgs[1] = list.data.SysPic2Url;
          imgs[2] = list.data.SysPic3Url;
          this.bannerImgs = imgs;
          this.appInfo = list.data;
        }
      });
    },
    // 热门企业
    GetAgencyPage () {
      WeGetAgencyPage(this.AgencyFrom).then((res) => {
        this.AgencyList = res.data.data;
        for (let i = 0; i < this.AgencyList.length; i++) {
          if (this.AgencyList[i].Imgs != '') {
            this.arrimg.push(this.AgencyList[i].Imgs.split(","));
          }
          // var classArr = this.AgencyList[i].ClassifyName.split(",");
          // this.AgencyList[i].ClassifyName = classArr;

        }
        // this.arrimg = this.arrimg.split(",");
        console.log(this.arrimg);
      });
    },
    // 时间格式化
    // 时间格式化
    dataFormat (value) {
      var year = value.substr(0, 4)
      var month = value.substr(5, 2)
      var day = value.substr(8, 2)
      var hour = value.substr(11, 2)
      var min = value.substr(14, 2)
      var second = value.substr(17, 2)
      return year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
    },
  },
};
</script>

<style scoped>
.homebanner img {
  width: 100%;
  vertical-align: top;
}
.wktime {
  padding: 0 20px;
}
.wktype {
  padding: 0 10px 10px;
}
.orginview .iconimg {
  width: 62%;
}
.orginview .linktext {
  font-size: 14px;
  margin-top: 10px;
}
.wktype .iconimg {
  width: 65%;
}
.wktype .wktext {
  margin-top: 10px;
  font-size: 15px;
}
.grouptitle {
  border-left: 4px solid #1989fa;
  text-indent: 15px;
  font-weight: normal;
  font-size: 15px;
  margin: 12px 10px;
  color: #000;
}
.brakline {
  height: 5px;
  width: 100%;
  background: #eee;
  display: block;
  content: '';
}
.swipeBox {
  width: 90%;
  height: 105px;
  padding: 5px 0;
  margin: 3px 5%;
  padding-bottom: 30px;
}
.swipeBox div:nth-child(1) {
  text-align: center;
  color: #000;
}
.swipeBox div:nth-child(2) {
  text-align: center;
  padding: 5px 0;
  font-size: 12px;
}
.swipeBox div:nth-child(3) {
  color: #000;
  font-size: 12px;
}
.flexOn {
  z-index: 10;
  right: 15px;
  bottom: 30px;
  position: fixed;
  width: 75px;
  height: 75px;
  line-height: 75px;
  background: #2196f3;
  border-radius: 15px;
  color: #fff;
  text-align: center;
  font-size: 14px;
}
</style>